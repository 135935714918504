import OauthService from './OauthService';
//import BaseService from './BaseService';
import URLCellRenderer from '../components/AGGrid/URLCellRenderer';
import JiraCellRenderer from '../components/AGGrid/JiraCellRenderer';
import PagerDutyCellRenderer from '../components/AGGrid/PagerDutyCellRenderer';
import KonosCellRenderer from '../components/AGGrid/KonosCellRenderer';



import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';
import { Logger } from 'aws-amplify';

const logger = new Logger('IssueTrackerService');



export default class IssueTrackerService extends OauthService {

    constructor() {
        let apiName = 'OriginsAPI';
        let collectionPath = '/services/issuetracker';
        super(apiName, collectionPath);
    }


    /**
    *  {
            "tasktype": "PagerDuty",
            "priority": "1 - Critical",
            "is_root_reference": "1",
            "resolved_at_timestamp": "2025-01-24 09:26:44",
            "opened_at_timestamp": "2025-01-24 06:49:54",
            "assigned_to": "Vinod Hiremath",
            "state": "resolved",
            "first_recorded_at_timestamp": "2025-01-27 20:59:59",
            "closed_at_timestamp": "2025-01-24 09:26:44",
            "short_description": "DART Tool issue.  | CS0179105",
            "last_recorded_at_timestamp": "2025-01-27 20:59:59",
            "pagerduty_number": "33682",
            "category": "CPC Critical P1 Issue Triage",
            "SK": "v0",
            "PK": "ISSUE|PAGERDUTY|33682",
            "assignment_group": "Cloud Data Team"
        },
    */
    formatRowData(data) {
        logger.debug("formatRowData", data);

        var new_data = [];
        data.data.forEach((element) => {
            if (!element['summary_input']) element['summary_input'] = element['short_description']; 
            
            var value = [];
            
            if (element['pagerduty_number']) {
                element['pagerduty_number'] =  element['pagerduty_number'].replaceAll("PD:", "");
               
                value.push({
                    name: "PD:" +  element['pagerduty_number'],
                    href: "https://panasonic.pagerduty.com/incidents/" + element['pagerduty_number']
                });
                 //element['pagerduty_number'] = "PD:" + element['pagerduty_number'];
            }
            if (element['jira_number']) {
                value.push({
                    name: element['jira_number'],
                    href: "https://jira.panasonic.aero/browse/" + element['jira_number']
                });
            }
            if (element['konos_number']) {
                logger.debug("Konos", element['index']);
                if (element['index'] && element['sys_id']) {
                    if (element['konos_number'].substring(0, 2) === "CS") {
                        //sys_id: "40eb8749870302501541ed3e8bbb351b"
                        //index: "sn_customerservice_case-2024.06"
                        
                        value.push({
                            name: element['konos_number'],
                            href: "https://konos.panasonic.aero/now/nav/ui/classic/params/target/sn_customerservice_case.do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D"
                        });
                    }
                    else if (element['konos_number'].substring(0, 6) === "SCTASK") {
                        value.push({
                            name: element['konos_number'],
                            href: "https://konos.panasonic.aero/now/nav/ui/classic/params/target/sc_task.do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D"
                       });
                        
                    }
                    else if (element['konos_number'].substring(0, 3) === "CHG") {
                        value.push({
                            name: element['konos_number'],
                            href: "https://konos.panasonic.aero/now/nav/ui/classic/params/target/change_request.do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D"
                       });
                    }
                    else {
                        var index =  element['index'].substring(0, element['index'].indexOf("-"));
                        value.push({
                            name: element['konos_number'],
                            href: "https://konos.panasonic.aero/now/nav/ui/classic/params/target/"+index+".do%3Fsys_id="+element['sys_id']+"%26sysparm_stack%3D%26sysparm_view%3D"
                        });
                    }
                } 
                else {
                    value.push({
                        name: element['konos_number'],
                    });
                }
                
            }
            element['issue_ids'] = JSON.stringify(value);
            new_data.push(element);
            
        });
        /*
        data.forEach((element) => {
            if (!element['last_updated_date']) element['last_updated_date'] = element['created_date'];
            if (element['created_date']) element['created_date'] = element['created_date'].substring(0, 19).replace('T', ' ');
            if (element['last_updated_date']) element['last_updated_date'] = element['last_updated_date'].substring(0, 19).replace('T', ' ');

            checkboxes.forEach((checkbox)=> {
                if (element[checkbox] === undefined) element[checkbox] = false;
            });
        });
        */
        return new_data;
    }

    getMetaData(data) {
        return {
            pagination: false,
            total_records: data.data.length
        };
    }
    
    
    /**
     * If this function doesn't exist, the assumptino is it is true
     * The function is never actually called when inserting a record.
     */
    allowed_insert() {
        return false;
    }

    /**
     * 
     *     "tasktype": "Connectivity Issue",
     *     "impact": "3 - Low",
     *     "calculated_status": "Open",
     *     "last_round_robin_at_timestamp": "2025-02-04 17:21:15",
     *     "priority": "4 - Low",
     *     "is_root_reference": "1",
     *     "resolved_at_timestamp": "2025-02-03 10:54:59",
     *     "opened_at_timestamp": "2025-02-01 20:38:52",
     *     "konos_number": "CS0179938",
     *     "assigned_to": "Christopher Koski",
     *     "state": "Resolved",
     *     "first_recorded_at_timestamp": "2025-02-04 17:21:16",
     *     "short_description": "CS0179938 | Origins | Sum of Operator Live WISP Session connect Events by hour | status red | 2nd Feb 2025",
     *     "last_recorded_at_timestamp": "2025-02-04 17:21:16",
     *     "round_robin": "Bryan Mendoza-Trejo",
     *     "category": "Connectivity Issue",
     *     "SK": "v0",
     *     "PK": "ISSUE|KONOS|CS0179938",
     *     "assignment_group": "DART Devs",
     *     "summary_input": "CS0179938 | Origins | Sum of Operator Live WISP Session connect Events by hour | status red | 2nd Feb 2025",
     *     "issue_ids": "[{\"name\":\"CS0179938\"}]",
     *     "status_monitored": "Yes",
     *     "issue_type": "ETL",
     *     "platform": "Insights",
     *     "priority_input": "Low"
}
     */
    async save(data, metaData) {
        logger.debug("save1", data);
        
        var response = null;
       
        logger.debug("save update", data);
        
        var save_data = [];
        save_data['updated_by'] = metaData.app_state.user.session.login_name; 
        save_data['issue_ids'] = null; // don't save this.  for display only.
       
        try {
            var results = await this.update(null, data);

        //.then((results) => {
            logger.debug("save: update record", results);
             response = {
                success: true,
                updated: true // todo: need to check result to see if updated
             };
        //})
        }
        catch(err) {
            logger.debug('save: update record failed', err, data);
             response = {
                success: false,
                updated: false,
                response: {
                    data: {
                        error: "An error occurred"
                    }
                }
            };
        }
        
        return response;
    }


    getColumnDefs() {
        var column_width = 100;

        return [
        {
            headerName: 'Priority',
            openByDefault: false, // column: header expanded
            defaultExpanded: false, // form
            children: [
                {
                    field: "priority_input",
                    headerName: "Triage",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    key: "priority_input", // set to avoid dup keys (value is irrelevent)
                    required: false,
                    formType: "select",
                    options: [
                        "Low",
                        "Medium",
                        "High",
                        "Critical"
                    ],
                    getOptionLabel: (option) => { return option },
                    renderOption: (option) =>  { return option },
                    getValue: (data) => { // note that if this returns a value if it is null and will not show up in the grid, but will in the form.
                        //if (data.priority_input) 
                        return data.priority_input;
                        //else return "Medium";
                    },
                    newline: true
                },
                {
                    field: "priority",
                    headerName: "Original",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    columnGroupShow: "open"
                },
                 {
                    field: "impact",
                    headerName: "Impact",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    columnGroupShow: "open"
                },
            ]
        },
        {
            headerName: 'Reported',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                 {
                    field: "first_recorded_at_timestamp",
                    headerName: "First Recorded Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    sort: "desc"
                },
                  {
                    field: "opened_at_timestamp",
                    headerName: "Creation Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    columnGroupShow: "open"
                    
                },
                {
                    field: "last_recorded_at_timestamp",
                    headerName: "Last Recorded Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "last_updated_by",
                    headerName: "Last Updated By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "resolved_at_timestamp",
                    headerName: "Resolution Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "closed_at_timestamp",
                    headerName: "Closed Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                }
            ],

        },
        {
            headerName: 'Summary',
            openByDefault: false, // column: header expanded
            defaultExpanded: true,
            children: [
                 {
                    field: "category",
                    headerName: "Categoryr",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open",
                    
                },
                 {
                    field: "summary_input",
                    headerName: "Title",
                    filter: 'agTextColumnFilter',
                    width: column_width *4,
                    resizable: true,
                    formType: "text",
                    newline: true
                },
               
                {
                    field: "short_description",
                    headerName: "Original Description",
                    filter: 'agTextColumnFilter',
                    width: column_width *4,
                    resizable: true,
                    formType: "multiline",
                    disabled: true,
                    columnGroupShow: "open",
                    newline: true
                },
             
                
            ]
        },
        {
            headerName: 'Status',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "calculated_status",
                    headerName: "Status",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "readonly"
                },
                {
                    field: "state",
                    headerName: "State",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
            ]
        },
        {
            headerName: 'Assignment',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "round_robin",
                    headerName: "Round Robin",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "text"
                },
                {
                    field: "assigned_to",
                    headerName: "SME",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "readonly"
                },
                {
                    field: "assignment_group",
                    headerName: "Group",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                }
            ]
        },
         {
            headerName: 'Tickets',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "issue_ids",
                    headerName: "Ticket(s)",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    //formType: "readonly", hides from the form but not the grid
                    cellRenderer: URLCellRenderer,
                },
                {
                    field: "pagerduty_number",
                    headerName: "Pager Duty",
                    filter: 'agTextColumnFilter',
                    width: column_width *1.3,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open",
                    cellRenderer: PagerDutyCellRenderer
                },
                 {
                    field: "jira_number",
                    headerName: "Jira",
                    filter: 'agTextColumnFilter',
                    width: column_width *1.3,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open",
                    cellRenderer: JiraCellRenderer
                },
                {
                    field: "konos_number",
                    headerName: "KONOS",
                    filter: 'agTextColumnFilter',
                    width: column_width *1.3,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open",
                    cellRenderer: KonosCellRenderer
                },
            ]
        },
        {
            headerName: 'Resolution',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "resolution",
                    headerName: "Resolution",
                    filter: 'agTextColumnFilter',
                    width: column_width *4,
                    resizable: true,
                    formType: "multiline"
                },
            ]
        },
        {
            headerName: 'Reccurence',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                  {
                    field: "recurrent_possible",
                    headerName: "Reccurence",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    key: "priority_input", // set to avoid dup keys (value is irrelevent)
                    formType: "select",
                    options: [
                        "Yes",
                        "No",
                    ],
                    getOptionLabel: (option) => { return option },
                    renderOption: (option) =>  { return option },
                    getValue: (data) => { 
                        return data.recurrent_possible;
                    }
                }
            ]
        },
        {
            headerName: 'Monitoring',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "status_monitored",
                    headerName: "Status",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    key: "priority_input", // set to avoid dup keys (value is irrelevent)
                    formType: "select",
                    options: [
                        "Yes",
                        "Pending",
                        "Not Applicable"
                    ],
                    getOptionLabel: (option) => { return option },
                    renderOption: (option) =>  { return option },
                    getValue: (data) => { 
                        return data.status_monitored;
                    },
                },
            ]
        },
        {
            headerName: 'KB',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "knowledge_base",
                    headerName: "KB",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "text"
                },
            ]
        },
        {
            headerName: 'Category',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                 {
                    field: "issue_type",
                    headerName: "Type",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    key: "priority_input", // set to avoid dup keys (value is irrelevent)
                   
                    formType: "select",
                    options: [
                        "Backfill",
                        "Investigation",
                        "Bug",
                        "ETL",
                        "UI",
                        "Admin",
                        "Alert",
                        "Networking",
                        "Upstream",
                        "Maintence"
                    ],
                    getOptionLabel: (option) => { return option },
                    renderOption: (option) =>  { return option },
                    getValue: (data) => { 
                        return data.issue_type;
                    },
                },
                {
                    field: "platform",
                    headerName: "Platform",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    key: "priority_input", // set to avoid dup keys (value is irrelevent)
                    
                    formType: "select",
                    options: [
                        "DART",
                        "DART Rack",
                        "DARTce",
                        "FDS",
                        "Insights",
                        "Netflow",
                        "Origins",
                        "SLA",
                        "Unity"
                    ],
                    getOptionLabel: (option) => { return option },
                    renderOption: (option) =>  { return option },
                    getValue: (data) => { 
                        return data.platform;
                    },
                },
                
               
                {
                    field: "tasktype",
                    headerName: "Task Type",
                    filter: 'agTextColumnFilter',
                    width: column_width *2,
                    resizable: true,
                    formType: "readonly"
                },
                
                
                
                
                
             
               
                  
            ]
        }
        ];
    }
}
