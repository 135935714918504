import OauthService from './OauthService';
import BaseService from './BaseService';
import { Logger } from 'aws-amplify';
import BooleanCellRenderer from '../components/AGGrid/BooleanCellRenderer';
import cron         from 'cron-validate'; // https://www.npmjs.com/package/cron-validate

const logger = new Logger('DataFiltetService');

const checkboxes = [
    "enabled"
];





export default class DataFilterService extends BaseService {

    constructor() {
        //let apiName = 'OriginsAPI';
        //let collectionPath = '/services/datafilter';
        //super(apiName, collectionPath);
        
        let apiName = 'RulesEngineAPI';
        let collectionPath = '/data_filter';
        super(apiName, collectionPath);
    }
    
    /**
     * CSVToArray parses any String of Data including '\r' '\n' characters,
     * and returns an array with the rows of data.
     * @param {String} CSV_string - the CSV string you need to parse
     * @param {String} delimiter - the delimeter used to separate fields of data
     * @returns {Array} rows - rows of CSV where first row are column headers
     */
    static csvToArrayOfObjects (CSV_string, delimiter) {
       delimiter = (delimiter || ","); // user-supplied delimeter or default comma
    
       var pattern = new RegExp( // regular expression to parse the CSV values.
         ( // Delimiters:
           "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
           // Quoted fields.
           "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
           // Standard fields.
           "([^\"\\" + delimiter + "\\r\\n]*))"
         ), "gi"
       );
    
       var rows = [[]];  // array to hold our data. First row is column headers.
       // array to hold our individual pattern matching groups:
       var matches = false; // false if we don't find any matches
       // Loop until we no longer find a regular expression match
       while (matches = pattern.exec( CSV_string )) {
           var matched_delimiter = matches[1]; // Get the matched delimiter
           // Check if the delimiter has a length (and is not the start of string)
           // and if it matches field delimiter. If not, it is a row delimiter.
           if (matched_delimiter.length && matched_delimiter !== delimiter) {
             // Since this is a new row of data, add an empty row to the array.
             rows.push( [] );
           }
           var matched_value;
           // Once we have eliminated the delimiter, check to see
           // what kind of value was captured (quoted or unquoted):
           if (matches[2]) { // found quoted value. unescape any double quotes.
            matched_value = matches[2].replace(
              new RegExp( "\"\"", "g" ), "\""
            );
           } else { // found a non-quoted value
             matched_value = matches[3];
           }
           // Now that we have our value string, let's add
           // it to the data array.
           rows[rows.length - 1].push(matched_value);
       }
       
      logger.debug("csvToArrayOfObjects", rows);
      return rows;
    }
    
    /**
     * Look up the header ids from the titles
     * Then turn each row into an key/value object
     */
    static parseRawObject(rows) {
        var header = rows[0];
        logger.debug("parseRawObject1", header);
        var  colDefs = DataFilterService.getColDefs();
        var new_data = [];
        if (header) {
            logger.debug("parseRawObject1a");
            var header_fields = [];
            header.forEach((header_text) => {
                var group_header_name = header_text.substring(0, header_text.indexOf(':')).trim();
                var header_name =  header_text = header_text.substring(header_text.indexOf(':')+1).trim();
                
                var group = colDefs.filter(obj => {return (obj['headerName'] === group_header_name) })[0];
                if (group && group.children) {
                    var child = group.children.filter(obj => {return (obj['headerName'] === header_name) })[0];
                    if (child) {
                        header_fields.push(child.field);
                    }
                    
                }
            });
            for (var i = 1; i < rows.length; i++) {
                var new_row = {};
                header_fields.forEach((field, index) => {
                    logger.debug("parseRawObject3", field, index, rows[i][index]);
                    new_row[field] = rows[i][index];    
                });
                new_data.push(new_row);
            }
            logger.debug("parseRawObject", new_data);
        }
        return new_data;
    }

    /**
     * {
     *   "enabled": true,
     *   "filter_name": "hardware_error",
     *   "filter_description": "hardware_error",
     *   "filter_query": "{\"tsyslog_bite\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[{\"id\":\"3fd7afe3-d3b6-4c8a-be5a-2568aa70e5be\",\"field\":\"bite_code\",\"operator\":\"=\",\"valueSource\":\"value\",\"value\":\"1\"},{\"id\":\"2e3c6e96-31e4-4270-a14f-d0e5a5b6248b\",\"field\":\"mmid\",\"operator\":\"LIKE\",\"valueSource\":\"value\",\"value\":\"00001\"}],\"combinator\":\"and\",\"not\":false},\"dl_pac_origins_aircraft_v1.logs\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[],\"combinator\":\"and\",\"not\":false},\"dl_pac_origins_pacwisplive_v1.wisp_session_connect\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[],\"combinator\":\"and\",\"not\":false},\"dl_pac_dp_fds_raw_prod_v1.mst_fds_flight_events\":{\"id\":\"2b2932f3-0ca4-4400-af05-720f4dbc82fe\",\"rules\":[],\"combinator\":\"and\",\"not\":false}}",
     *   "created_date": 1738326951.483211,
     *   "created_by": "vinod.hiremath@panasonic.aero",
     *   "last_updated_date": 1738326951.496676,
     *   "last_updated_by": "vinod.hiremath@panasonic.aero"
     * }
     */
    formatRowData(data) {
        logger.debug("formatRowData0", data, data.data);
        return data.data;
    }

    getMetaData(data) {
        logger.debug("getMetaData", data);
        return {
            pagination: false,
            total_records: data.data.length
        };
    }

    getCloneRecord(record) {
        record['filter_name'] = record['filter_name'] + ' (clone)';
        record['original_title'] = "";
        record['last_updated_date'] = null;
        return record;
    }
    
    uploadData(data) {
        logger.debug("uploadData", data);
        try {
            var json = JSON.parse(data);
        }
        catch (e) {
            logger.debug("uploadData error", e);
            json = DataFilterService.parseRawObject(DataFilterService.csvToArrayOfObjects(data));
            
        }
        if (json.length) {
            var response = "";
            var response_count = 0;
            json.forEach((element) => {
               if (element['filter_name'])  {
                   response_count++;
                   response += element['filter_name'] + "\n";
               }
            });
            response += "Records processed:  " + response_count;
             return response;
        }
        else {
            return "bad data";
        }
       
    }
    
    
    
    async delete(data, recordID) {
        logger.debug("delete", data, recordID);
         var response = null;
        // send array of all names. e.g. ['myname', 'another_name'] to the DELETE
         try {
            var results = await this.deleteRecord("", "[\"" + data['filter_name'] + "\"]");  
            response = {
                success: true,
                updated: true,
                data: results
            };
        }
        catch(err) {
            response = {
                success: false,
                updated: false,
                data: results
            };
        }
        return response;
    }

    /**
     * {
     *   "enabled": false,
     *   "filter_name": "filter",
     *   "filter_description": "Updated description",
     *   "filter_query": "{updated query}",
     *   "user": "someother_user@pansonic.aero"
     * }
     */
    async save(data, metaData) {
        logger.debug("save1", data, metaData);

        //check the PK and set bool to true/false
        checkboxes.forEach((checkbox)=> {
            if (data[checkbox])
                data[checkbox] = true;
            else
                data[checkbox] = false;
        });
        
        // verify the cron value
        if (data['schedule']) {
            const cronResult = cron(data['schedule']);
            if (!cronResult.isValid()) {

                return {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: "You have an error in the cron format."
                        }
                    }
                }
            }
        }
        var new_data = {};
        new_data['user'] = metaData.app_state.user.session.login_name;
        new_data['filter_name'] = data['filter_name'];
        new_data['filter_description'] = data['filter_description']?data['filter_description']:"";
        new_data['filter_query'] = data['filter_query'];
        new_data['enabled'] = data['enabled'];
        new_data['cron_rule'] = data['cron_rule'];
        new_data['filter_team'] = data['filter_team'];
        
        
        

        logger.debug("save2", data, new_data, metaData);
        var response = null;
        if (data['last_updated_date']) { //update because it already had a date
            logger.debug("save update", data);
            try {
                var results = await this.update("", new_data);  
                response = {
                    success: true,
                    updated: true,
                    data: results
                };
            }
            catch(err) {
            //.catch((err) => {
                logger.debug('save: update record failed', err, new_data);
                 response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: "An error occurred"
                        }
                    }
                };

            //});
            }
        }
        else { //insert
            logger.debug("save insert", new_data);
            await this.add(new_data)
            .then((results) => {
                logger.debug("save: insert record", results);
                if (results.success == false) {
                    response =  {
                        success: false,
                        updated: false,
                        response: {
                            data: {
                                error: results.error.message
                            }
                        }
                    };
                }
                else {
                    response = {
                        success: true,
                        updated: true
                    };
                }
            })
            .catch((err) => {
                logger.debug("save: insert record error1", err);
                response = {
                    success: false,
                    updated: false,
                    response: {
                        data: {
                            error: err.message
                        }
                    }
                };
            });
        }
        return response;
    }


    getColumnDefs() {
        return DataFilterService.getColDefs();
    }
    
    /**
     * This needs to be static to have the other static functions access it.
     */
    static getColDefs() {
        var column_width = 100;

        return [
        {
            headerName: 'History',
            openByDefault: false, // column: header expanded
            defaultExpanded: false,
            children: [
                {
                    field: "created_date",
                    headerName: "Creation Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "created_by",
                    headerName: "Created By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    columnGroupShow: "open"
                },
                {
                    field: "last_updated_date",
                    headerName: "Last Updated Date",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "readonly",
                    newline: true,
                    sort: "desc"
                },
                {
                    field: "last_updated_by",
                    headerName: "Last Updated By",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    formType: "readonly",
                    columnGroupShow: "open"
                },
            ]
        },
        {
            headerName: 'Details',
            defaultExpanded: true,
            children: [
                {
                    field: "enabled",
                    headerName: "Enabled",
                    filter: 'agTextColumnFilter',
                    cellRenderer: BooleanCellRenderer,
                    width: column_width,
                    formType: "boolean",
                    defaultValue: 1,
                },
                {
                    field: "filter_name",
                    headerName: "Test Case Name",
                    primaryKey: true, // makes the field read only when updating but read write when inserting
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "text",
                    required: true,
                    newline: true
                },
                {
                    field: "filter_team",
                    headerName: "Team",
                    filter: 'agTextColumnFilter',
                    width: column_width,
                    resizable: true,
                    key: "filter_team", // set to avoid dup keys (value is irrelevent)
                    formType: "select",
                    options: [
                        "Headend",
                        "Maint",
                        "CMI GUI"
                    ],
                    getOptionLabel: (option) => { return option },
                    renderOption: (option) =>  { return option },
                    getValue: (data) => { 
                        return data.filter_team;
                    },
                    required: true
                },
                
                {
                    field: "filter_description",
                    headerName: "Description",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "multiline",
                    required: false
                }
            ]
        },
         {
            headerName: 'Scheduling',
            defaultExpanded: true,
            children: [
                {
                    field: "cron_rule",
                    headerName: "Cron Rule",
                    filter: 'agTextColumnFilter',
                    width: column_width * 2,
                    resizable: true,
                    formType: "cron",
                    required: true
                }
            ]
        },
        {
            headerName: 'Rule',
            defaultExpanded: true,
            children: [
                {
                    field: "filter_query",
                    headerName: "Filter Query",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "querybuilder",
                    required: false
                },
                {
                    /** Note that this field should match the querybuilder name 
                     *  with "_jsonlogic" appended to it.
                     * The value for JSONLogic will be set when the filterQuery
                     * value is saved. 
                     */
                    field: "filter_query_jsonlogic",
                    headerName: "JSONLogic",
                    filter: 'agTextColumnFilter',
                    width: column_width * 4,
                    resizable: true,
                    formType: "hidden",
                    required: false
                }
            ]
        }];
    }
}
