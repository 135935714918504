import _ from 'lodash';
import React, { useContext } from 'react';
import { Logger } from 'aws-amplify';
import Home from '../views/Home';

import OpsAircraft from '../views/OpsAircraft';
import OpsAlertConfig from '../views/OpsAlertConfig';
import OpsDataFilter from '../views/OpsDataFilter';
import OpsCustomer from '../views/OpsCustomer';
import OpsExclusionParams from '../views/OpsExclusionParams';
import OpsIssueTracker from '../views/OpsIssueTracker';
import OpsReports from '../views/OpsReports';
import OpsSLAMetric from '../views/OpsSLAMetric';


import {
    Switch,
    Route,
    useRouteMatch,
    useLocation
}
from 'react-router-dom';
import AppContext         from 'pac-responsive-ui-framework/core/AppContext';
import { setReportList, getReportList, getReportDetails, getQuicksightEmbedURL } from '../data/ReportList';
import { useHistory } from 'react-router-dom';
import PageVisitService   from '../services/PageVisitService';
const logger = new Logger('RouterOperations');
const page_visit_service = new PageVisitService();

function logReport(app_context, report_details, url) {
    var timestamp = new Date().getTime();
    logger.debug('logReport', timestamp, report_details, app_context, url);
    if (!_.get(app_context, 'state.user.session.recent_reports', false)) _.set(app_context, 'state.user.session.recent_reports', []);  
    app_context.state.user.session.recent_reports.unshift({time: timestamp, report: report_details.title});
    
    page_visit_service.add({
      report_name: report_details.title, 
      report_url: url,
      operator_icao_code: app_context.state.user.session.operator_icao_code
    }).then((data) => {
      logger.debug("PageVisitService success", data);
    }).catch((err) => {
      logger.debug('PageVisitService error', err);
    });
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function RouterOperations(props) {
    var app_context = useContext(AppContext);
    let history = useHistory();
    
    let match = useRouteMatch();
    let query = useQuery();
    const location = useLocation();
    
    setReportList(app_context.state.reports_list);
    var report_list = getReportList([], app_context.state);
    var report = false;
    for (var i = 0; i < report_list.length; i++) {
        logger.debug(location.pathname, report_list[i].href);
        if (report_list[i].href == location.pathname) {
            report = report_list[i];
        }
    }
    if (!report) {
        history.push('/');
        return;
    }
    
    const location_array = location['pathname'].split("/");
    logger.debug(match, query, location_array, location);
    var param;
    param = location_array[3];
    
    logReport(app_context, report, location.pathname);
    
    
    return (
        <Switch>
         <Route path={`${match.path}/customer`}>
           <OpsCustomer title={report.title}/>
         </Route>
         <Route path={`${match.path}/aircraft`}>
           <OpsAircraft title={report.title}/>
         </Route>
         <Route path={`${match.path}/reports`}>
           <OpsReports title={report.title} recordID={param} />
         </Route>
         <Route path={`${match.path}/alerts`}>
           <OpsAlertConfig title={report.title}/>
         </Route>
         <Route path={`${match.path}/issuetracker`}>
           <OpsIssueTracker title={report.title}/>
         </Route>
         <Route path={`${match.path}/exclusion_params`}>
           <OpsExclusionParams title={report.title}/>
         </Route>
         <Route path={`${match.path}/slametrics`}>
           <OpsSLAMetric title={report.title}/>
         </Route>
         <Route path={`${match.path}/datafilters`}>
           <OpsDataFilter title={report.title}/>
         </Route>
         <Route path={match.path}>
           <Home />
         </Route>
       </Switch>
    );
}
