/**
 * @experimental
 * 
 * This tests the functionality of the grid component
 */

import React from 'react';

import View from 'pac-responsive-ui-framework/core/View';

import AGGrid from '../components/AGGrid';
import Typography from '@material-ui/core/Typography';
import Service from '../services/IssueTrackerService';

export default function OpsIssueTracker(props) {
    const service = new Service();
   var header = <Typography variant="h5">Issue Tracker</Typography>;

    return (
        <View title={props.title} fixedContent={true}>
           <AGGrid header={header} service={service} columnDefs={service.getColumnDefs()} filename="issue_tracker"/>
       </View>
    );
}
