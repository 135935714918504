
export default function URLCellRenderer() {
}

URLCellRenderer.prototype.init = function (params) {
    console.log('URLCellRenderer.prototype.init', params);
   
    if (params.value) {
        var span = document.createElement('span');
        const obj = JSON.parse(params.value);
        obj.forEach((element, index) => {
            var name = element.name;
            if (index > 0) name = " " + name;
            if (element.href) {
                var a = document.createElement('a');
                a.innerText  = name;
                a.target = "_blank";
                a.href = element.href;
                span.appendChild(a);
            }
            else span.appendChild(document.createTextNode(name));
        });
        this.eGui = span;
    }
    else this.eGui ="";
};

URLCellRenderer.prototype.getGui = function () {
    return this.eGui;
};

